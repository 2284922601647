import React from "react"

function SearchIcon({ size, position, color }) {
  return (
    <svg
      width={size}
      height={size}
      className={`quiz__search-icon quiz__search-icon--${position}`}
      viewBox={`0 0 192 141`}
      fill="none"
    >
      <path
        d="M169.92 47.331c0-23.902-19.446-43.347-43.347-43.347-23.9 0-43.346 19.447-43.346 43.347 0 23.9 19.446 43.347 43.346 43.347 23.901 0 43.347-19.445 43.347-43.347zm-74.225 0c0-17.024 13.851-30.876 30.876-30.876s30.876 13.851 30.876 30.876-13.851 30.877-30.876 30.877-30.876-13.85-30.876-30.877z"
        fill={color}
      />
      <path
        d="M80.904 95.675c11.934 11.29 28.02 18.233 45.705 18.233 11.023 0 23.272-4.589 28.026-6.562 23.39-9.704 37.355-31.916 37.355-59.417 0-10.017-1.912-20.438-5.529-30.138-9.789-26.25-38.809-37.341-63.228-36.689-34.267.917-63.214 31.24-63.214 66.215a66.243 66.243 0 004.362 23.671c-13.676 6.435-27.195 13.267-40.55 20.323-6.557 3.462-14.414 6.13-20.455 10.415-9.82 6.968 4.394 29.35 9.562 35.55.029.032.06.051.086.083.909 2.773 3.844 4.798 6.901 2.669 20.643-14.365 39.934-30.579 60.98-44.353zM123.568-6.431c19.986-.533 43.57 8.094 51.208 28.578 3.104 8.32 4.744 17.235 4.744 25.782 0 22.619-10.813 40.077-29.663 47.898-8.465 3.514-17.153 5.61-23.246 5.61-29.839 0-54.118-24.276-54.118-54.118 0-28.394 23.39-53.008 51.075-53.75zm-54.99 86.342a66.798 66.798 0 005.58 8.353c-8.678 5.756-17.078 11.895-25.4 18.146-2.637-4.288-5.527-8.403-8.602-12.385 9.416-4.817 18.863-9.577 28.421-14.114zm-58.185 29.266c6.934-3.449 13.83-6.976 20.72-10.515 3.488 4.407 6.717 8.988 9.613 13.811-7.266 5.499-14.537 10.994-21.96 16.281-4.382-5.762-7.175-12.455-8.373-19.577z"
        fill={color}
      />
    </svg>
  )
}

export default SearchIcon
