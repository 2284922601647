import Baseline from "./../components/home/baseline/baseline.js"
import FeaturedProducts from "../components/featured-products/featured-products.js"
import HeroSlider from "../components/home/hero-slider/hero-slider"
import Instagram from "../components/home/instagram/instagram.js"
import Layout from "../components/layout/layout"
import Presentation from "../components/home/presentation/presentation.js"
import Quiz from "../components/home/quiz/quiz.js"
import React from "react"
import Reassurance from "../components/home/reassurance/reassurance.js"
import SEO from "../components/seo.js"
import ScrollAnimation from "react-animate-on-scroll"
import Wall from "~/components/home/wall/wall.js"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

const HomePage = ({ data, pageContext, location }) => {
  const homePageDatas = data.allWpPage.nodes[0]
  const content = homePageDatas.homepage_content
  const seo = pageContext.page.seo

  return (
    <Layout location={location}>
      <SEO meta={seo} />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "name": "funkyveggie.fr",
              "url": "https://www.funkyveggie.fr"
            }
          `}
        </script>
        <script
          async
          type="text/javascript"
          src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=TYyQZq"
        ></script>
      </Helmet>
      <HeroSlider {...content} />

      <Baseline {...content} />

      <ScrollAnimation animateIn="fadeInBottom" duration={1}>
        <FeaturedProducts withSingleTitle={false} {...content} />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInBottom" duration={1}>
        <FeaturedProducts withSingleTitle={false} products={content.packs} />
      </ScrollAnimation>

      {content && content?.quiz && !content.quiz.hideSection && (
        <ScrollAnimation animateIn="fadeInBottom" duration={0.5}>
          <Quiz {...content} />
        </ScrollAnimation>
      )}

      <ScrollAnimation animateIn="fadeInBottom" duration={1}>
        <Presentation {...content} />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInBottom" duration={1}>
        <Reassurance {...content} />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInBottom" duration={1}>
        <Wall {...content} />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInBottom" duration={1}>
        <Instagram {...content} />
      </ScrollAnimation>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    allWpPage(filter: { slug: { eq: "home" } }) {
      nodes {
        slug
        homepage_content {
          heroSlider {
            buttonText
            isNew
            text
            title
            color
            isPackshot
            buttonLink {
              url
              title
            }
            backgroundImg {
              sourceUrl
            }
            image {
              sourceUrl
            }
          }
          headline
          products {
            subtitle
            title
            produits {
              ... on WpProduct {
                slug
                uri
                product_content {
                  productType
                }
                Product {
                  productId
                  productColor
                  productName
                  variantName
                  productImage {
                    sourceUrl
                    altText
                    slug
                    uri
                  }
                }
              }
            }
          }
          packs {
            subtitle
            title
            produits {
              ... on WpProduct {
                slug
                uri
                product_content {
                  productType
                }
                Product {
                  productId
                  productColor
                  productName
                  variantName
                  productImage {
                    sourceUrl
                    altText
                    slug
                    uri
                  }
                }
              }
            }
          }
          quiz {
            buttonLink
            buttonTitle
            title
            hideSection
          }
          wallOfLove {
            author
            fieldGroupName
            source
            text
            date
          }
          funkyWhat {
            buttonTitle
            centerText
            leftText
            rightText
            title
            buttonLink
          }
          bannerTrusts {
            title
            description
            icon {
              altText
              localFile {
                publicURL
              }
            }
          }
          instagram {
            buttonTitle
            buttonLink
            text
            title
            image {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`

export default HomePage
