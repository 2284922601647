import { Link } from "gatsby"
import PlanetIcon from "../../../images/titles/planet.svg"
import { ROUTES } from "../../../../constants.js"
import React from "react"
import TitleIcon from "../../decoration/title-icon.js"

const Presentation = ({
  funkyWhat: { buttonTitle, centerText, leftText, rightText, title },
}) => (
  <section className={"presentation"}>
    <div className="container">
      <h2 className={"presentation__title"}>
        <span dangerouslySetInnerHTML={{ __html: title }} /> 
        <TitleIcon color="#ff6b00" icon={<img src={PlanetIcon} alt="" />} />
      </h2>
      <div className={"presentation__cols"}>
        <p className={"presentation__text"}>{leftText}</p>
        <p className={"presentation__text"}>{rightText}</p>
      </div>
      <h3 className={"presentation__subtitle"}>{centerText}</h3>
    </div>
    <Link
      to={ROUTES.ADVENTURE}
      title={buttonTitle}
      className={"link link--black"}
    >
      {buttonTitle}
    </Link>
  </section>
)

export default Presentation
