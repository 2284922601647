import AppleIcon from "~/images/social/apple-podcast.svg"
import FacebookIcon from "~/images/social/facebook.svg"
import GoogleIcon from "~/images/social/google.svg"
import InstaIcon from "~/images/social/instagram.svg"
import React from "react"
import StickerLeft from "~/components/decoration/sticker-left.js"
import StickerRight from "~/components/decoration/sticker-right.js"

const Card = ({ source, author, date, text, activeColor }) => {
  return (
    <div className={"card"}>
      <StickerLeft className="left-slice" color={activeColor} />
      <div className="content" style={{ backgroundColor: activeColor }}>
        <span
          className="message"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        <img src={getSourceIcon(source)} className="source" alt="" />
        <span className="author">{author}</span>
        <span className="date">{date}</span>
      </div>
      <StickerRight className="right-slice" color={activeColor} />
    </div>
  )
}

const getSourceIcon = source => {
  switch (source) {
    case "facebook": {
      return FacebookIcon
    }
    case "google": {
      return GoogleIcon
    }
    case "podcast": {
      return AppleIcon
    }
    case "instagram": {
      return InstaIcon
    }
    default: {
      return FacebookIcon
    }
  }
}

export default Card
