import { Link } from "gatsby"
import { ROUTES } from "../../../../constants.js"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Wave from "../../decoration/wave.js"
import Lazyload from "react-lazyload"

const Reassurance = ({ bannerTrusts }) => (
  <section className={"reassurance"}>
    <Wave color="#ffffff" position="top" />
    <div className="container">
      <ul>
        {bannerTrusts &&
          bannerTrusts.map(({ title, description, icon }, i) => (
            <li key={i}>
              {i === bannerTrusts.length - 1 && 
                <Link to={ROUTES.FAQ}></Link>
              }
              <ScrollAnimation
                animateIn="fadeInBottom"
                duration={1}
                delay={150 * i}
              >
                {!!icon && !!icon.localFile && !!icon.localFile.publicURL && (
                  <Lazyload>
                    <div className="reassurance__imageHolder">
                      <img
                        width="80"
                        height="80"
                        className={"reassurance__image"}
                        src={icon.localFile.publicURL}
                        alt=""
                      />
                    </div>
                  </Lazyload>
                )}
                <h3 className={"reassurance__title"}>{title}</h3>
                {!!description && (
                  <p className={"reassurance__description"}>{description}</p>
                )}
              </ScrollAnimation>
            </li>
          ))}
      </ul>
    </div>
    <Wave color="#ffffff" position="bottom" />
  </section>
)

export default Reassurance
