import InstaIcon from "./insta-icon.js"
import React from "react"
import Wave from "../../decoration/wave.js"
import useWindowSize from "../../../helpers/hooks/useWindowSize.js"
import Lazyload from "react-lazyload"
import Img from '../../../helpers/img'

const Instagram = ({ instagram: { buttonTitle, text, title, image } }) => {
  const size = useWindowSize()
  return (
    <section className={"instagram"}>
      <Wave color="#FFFFFF" position="top" />
      <div className={"container"}>
        <div className={"instagram__edito"}>
          <h2 className={"instagram__title"}>
            {title}
            {!size.isMobile && <InstaIcon />}
          </h2>
          <p>
            {size.isMobile && <InstaIcon />}
            {text}
          </p>
          <a
            href={"https://www.instagram.com/funky_veggie/"}
            title={"Nous suivre"}
            target={"blank"}
            className={"link link--black"}
          >
            {buttonTitle}
          </a>
        </div>
        {!!image && !!image.sourceUrl && (
          <div className={"instagram__image"}>
            <Lazyload>
              <Img 
                source={image.sourceUrl}
                width={600}
                alt={image?.altText ? image?.altText : "Instagram Funky Veggie"}
              />
            </Lazyload>
          </div>
        )}
      </div>
      <Wave color="#FFFFFF" position="bottom" />
    </section>
  )
}

export default Instagram
