import React from "react"

const InstaIcon = () => (
  <svg width={75} height={73} viewBox="0 0 75 73" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.59 5.889c-6.331-.531-13.755.074-21.328.714-7.33.619-11.646 1.836-14.523 3.336-2.804 1.46-4.46 3.296-6.332 5.719-.475.615-.96 1.639-1.384 3.09-.414 1.418-.72 3.073-.935 4.812a58.82 58.82 0 00-.357 9.256l.003.067v.066c0 1.614.127 7.558.641 18.11 1.24 7.958 3.852 11.363 7.237 13.156 3.805 2.015 9.117 2.36 16.838 2.685l1.566.067c6.992.3 13.426.575 18.995.058 6.003-.557 10.42-1.997 13.256-4.793 2.359-2.325 3.66-5.54 4.34-9.397.685-3.882.693-8.103.693-12.274 0-.68.004-1.392.008-2.132.022-3.862.048-8.468-.48-13.086-.634-5.537-2.024-10.602-4.793-14.098-2.513-3.172-7.046-4.819-13.445-5.356zm.474-5.667c6.83.573 13.403 2.415 17.423 7.49 3.764 4.75 5.313 11.104 5.986 16.984.571 4.99.54 9.993.517 13.847-.004.715-.009 1.39-.009 2.018v.115c0 4.05 0 8.732-.778 13.147-.79 4.48-2.428 8.99-5.949 12.46-4.248 4.188-10.285 5.807-16.719 6.404-5.971.555-12.786.262-19.654-.034l-1.67-.071c-7.305-.308-14.08-.599-19.257-3.34-5.578-2.955-8.834-8.4-10.217-17.468l-.022-.144-.007-.146C.192 40.944.056 34.862.052 33.016c-.111-2.46-.073-6.33.397-10.152.238-1.93.593-3.906 1.12-5.71.517-1.77 1.25-3.56 2.343-4.974 2.048-2.652 4.31-5.257 8.203-7.285 3.818-1.99 8.975-3.31 16.67-3.959 7.453-.63 15.38-1.293 22.28-.714z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.164 20.465l.198.019c10.642 1.005 16.368 6.001 18.435 12.268 1.948 5.906.42 12.336-2.062 16.104-1.54 2.338-4.52 4.06-7.566 5.161-3.169 1.147-6.955 1.824-10.674 1.695-3.699-.128-7.57-1.065-10.661-3.403-3.186-2.411-5.26-6.103-5.592-11.1-.508-7.655 2.246-12.91 6.156-16.213 3.772-3.187 8.395-4.377 11.568-4.522l.198-.009zm-8.101 8.877c-2.48 2.094-4.544 5.57-4.151 11.49.231 3.487 1.583 5.605 3.349 6.94 1.86 1.409 4.465 2.154 7.43 2.257 2.947.102 6.013-.443 8.546-1.36 2.657-.961 4.229-2.145 4.754-2.942 1.649-2.504 2.748-7.14 1.41-11.193-1.208-3.664-4.637-7.49-13.367-8.369-2.207.141-5.429 1.029-7.971 3.177z"
      fill="#fff"
    />
    <path
      d="M52.856 19.664c-.257-4.177 2.753-5.372 4.291-5.448 5.578.567 5.257 5.448 4.077 7.377-1.18 1.93-8.046 3.292-8.368-1.93z"
      fill="#fff"
    />
  </svg>
)

export default InstaIcon
