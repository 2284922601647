import React from "react"
import SearchIcon from "./search-icon.js"
import Wave from "../../decoration/wave.js"

const Quiz = ({ quiz: { buttonLink, buttonTitle, title } }) => (
  <section className={"quiz"}>
    <Wave color="#ffffff" position="top" />
    <div className="container">
      <SearchIcon size="192" color="#ffffff" position="top" />
      <SearchIcon size="192" color="#ffffff" position="bottom" />
      <p className={"quiz__text"}>{title}</p>
      <a
        href={buttonLink}
        target="_blank"
        className={"link link--white"}
        title={buttonTitle}
        rel="noreferRer"
      >
        {buttonTitle}
      </a>
    </div>
    <Wave color="#ffffff" position="bottom" />
  </section>
)

export default Quiz
