import React from "react"
import parse from "html-react-parser"

const Baseline = ({ headline }) => {
  return (
    <section className={"baseline container"}>
      {parse(headline, {
        replace: domNode => {
          if (domNode.name === "strong" || domNode.name === "span") {
            return React.createElement(
              "span",
              { className: "text-hilight" },
              domNode.children[0].data
            )
          }
        },
      })}
    </section>
  )
}

export default Baseline
