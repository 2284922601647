import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import NewIcon from "../../../images/home/new.svg"
import TripleQuote from "../../../images/icons/triple-quote.svg"
import useWindowSize from "../../../helpers/hooks/useWindowSize"
import Img from "../../../helpers/img"
import Lazyload from "react-lazyload"

const HeroSlider = ({ heroSlider }) => {
  
  const COLORS = [
    "#8CD361",
    "#DCCE9E",
    "#f39414",
    "#fa004c",
    "#00b1f0",
    "#9f52c1",
  ]

  const sizes = useWindowSize()

  const [slideIndex, setSlideIndex] = useState({ oldSlide: 0, activeSlide: 0 })
  const [colors, setColors] = useState([])

  useEffect(() => {
    setColors(
      heroSlider?.length > 0 ? heroSlider.map(({ color }) => color) : COLORS
    )
  }, [])

  var settings = {
    dots: true,
    customPaging: function (i) {
      return <a>0{i + 1}</a>
    },
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => {
      setSlideIndex({ oldSlide: current, activeSlide: next })
    },
    afterChange: current => {
      setSlideIndex({ activeSlide: current })
    },
  }

  return (
    <header className="hero-slider">
      {/* BG */}
      <div className="background-container">
        <svg
          className="background"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 520"
          preserveAspectRatio="none"
        >
          <path
            d="M1440 0V507.32l-49.56 1.367c-81.92 3.836-248.23 11.506-413.446 11.309-110.326-.131-221.268-3.772-332.023-7.406-55.099-1.808-110.153-3.615-165.062-4.987-165.278-4.13-331.73-4.328-413.716-4.426h-.004L0 501.247V0h1440Z"
            fill={
              colors[slideIndex?.activeSlide || 0] ||
              COLORS?.[slideIndex?.activeSlide || 0] ||
              "#8CD361"
            }
          />
        </svg>
      </div>
      <Slider {...settings}>
        {heroSlider.map(
          (
            {
              title,
              text,
              buttonText,
              buttonLink,
              image,
              isPackshot,
              isNew,
            },
            i
          ) => (
            <div className="hero-slide" key={i}>
              <div className="hero-slide-container">
                {/* Left */}
                <div className="left">
                  <div className="image-wrapper">
                    {/* SVG Mask */}
                    {sizes?.width > 1024 && (
                      <svg
                        width={0}
                        height={0}
                        fill="none"
                        className="mask"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <clipPath id="svgCircleMask">
                            <path d="M516.13 263.83C516.13 331.05 475.02 390.11 432.82 434.88C388.76 481.63 338.37 493.19 270 493.19C195.23 493.19 132.15 473.97 87.7 419.33C52.58 376.16 23.87 324.47 23.87 263.83C23.87 196.61 45.5 129.78 87.7 85.01C131.76 38.26 201.63 22.81 270 22.81C338.37 22.81 411.48 38.26 455.54 85.01C497.74 129.78 516.13 196.61 516.13 263.83Z" />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    {sizes?.width <= 1024 && (
                      <svg
                        width={0}
                        height={0}
                        fill="none"
                        className="mask"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <clipPath id="svgCircleMask">
                            <path d="m305.85 156.46c0 39.86-24.36 74.89-49.37 101.44c-26.11 27.72-55.97 34.58-96.49 34.58c-44.31 0-81.69-11.4-108.03-43.8c-20.81-25.6-37.83-56.25-37.83-92.22c0-39.86 12.82-79.49 37.83-106.04c26.11-27.72 67.51-36.89 108.03-36.89c40.52 0 83.84 9.16 109.95 36.89c25.01 26.55 35.91 66.18 35.91 106.04z" />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    <div className="image-sub-wrapper">
                      {image?.sourceUrl && (
                        <Lazyload>
                          {/* <Img
                            classe={`image ${isPackshot ? "is-packshot" : ""}`}
                            width={500}
                            source={image.sourceUrl}
                            alt={title || "Funky Veggie présentation"}
                          /> */}
                          <img
                            className={`image ${
                              isPackshot ? "is-packshot" : ""
                            }`}
                          //   src={i !== 2 && (image.sourceUrl || image.sourceUrl !== '' || image.sourceUrl !== null) ? 
                          //     image.sourceUrl.replace('https://funky.buddybuddy.io/wp-content/uploads/', 'https://cdn.funkyveggie.fr/wp-content/uploads/') : image.sourceUrl
                          // }
                          src={image.sourceUrl}
                          alt={title ? "Funky Veggie - " + title : "Funky Veggie présentation"}
                          />
                        </Lazyload>
                      )}
                    </div>
                  </div>
                </div>
                {/* Right */}
                <div className="right">
                  {!!title && (
                    <div className="title-wrapper">
                      <img
                        className="quote"
                        src={TripleQuote}
                        alt="Décoration guillemets"
                      />
                      <h1
                        className="title"
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                    </div>
                  )}
                  {!!text && (
                    <p
                      className="text"
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  )}
                  {!!buttonLink && (
                    <Link to={buttonLink.url} title={buttonText}>
                      {buttonText}
                    </Link>
                  )}
                  {isNew && <img className="new" src={NewIcon} alt="NEW" />}
                </div>
              </div>
            </div>
          )
        )}
      </Slider>
    </header>
  )
}

export default HeroSlider
