import { Swiper, SwiperSlide } from "swiper/react"

import Card from "./card/card.js"
import React from "react"
import _ from "lodash"
import useWindowSize from "../../../helpers/hooks/useWindowSize.js"

const Wall = ({ wallOfLove }) => {
  const sizes = useWindowSize()

  // Shuffle all messages
  const loveMessages = _.shuffle(wallOfLove)

  // Random colors
  let colors = ["#ff6b00", "#e7004c", "#8347ad", "#00bed6"]
  colors = _.shuffle(colors)

  return (
    <section className={"wall"}>
      <div className="container">
        <h2 className={"title"}>
          Wall of
          <br />
          Love
        </h2>
        {sizes.isMobile ? (
          <Swiper
            className={"wall-slider"}
            slidesPerView={1.15}
            spaceBetween={16}
            breakpoints={{
              768: {
                slidesPerView: 2.5,
                spaceBetweenSlides: 24,
              },
              640: {
                slidesPerView: 1.5,
                spaceBetweenSlides: 24,
              },
              540: {
                slidesPerView: 1.15,
                spaceBetweenSlides: 24,
              },
            }}
          >
            {loveMessages.slice(0, 4).map((props, i) => (
              <SwiperSlide tag="div" key={i}>
                <Card {...props} activeColor={colors[i]} index={i} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="cards">
            {loveMessages.slice(0, 4).map((props, i) => (
              <Card {...props} activeColor={colors[i]} key={i} />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default Wall
